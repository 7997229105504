import { useEffect, useState } from 'react';

export function useChatOptions(chatType: string) {
    const [inputText, setInputText] = useState<string>('');
    const [messageOptions, setMessageOptions] = useState<string[]>([]);

    const emailTemplate = `1. **메일의 목적**:
2. **수신자 정보**:
- 이름: 
- 직책: 
- 회사명: 
- 이메일 주소: 
3. **발신자 정보**:
- 이름: 
- 직책: 
- 회사명: 
4. **메일 본문에 포함할 주요 내용**:
5. **추가 정보 또는 특별 요청사항**:
6. **서명 정보**:
- 이름: 
- 직책: 
- 연락처: 
- 회사명:`;

    useEffect(() => {
        if (chatType === 'mail') {
            setInputText(emailTemplate);
            setMessageOptions([]);
        } else if (chatType === 'korea') {
            setInputText('');
            setMessageOptions([
                '한국어의 존댓말과 반말의 차이는 무엇인가요?',
                '한국어에서 가장 어려운 발음은 무엇인가요?',
                '한국어 문법의 기본 구조는 어떻게 되나요?',
                '한국어의 한자어와 순우리말의 차이점은 무엇인가요?',
            ]);
        } else {
            setInputText('');
            setMessageOptions([
                '근무 시간과 휴식 시간에 대해 알고 싶어요.',
                '병가나 연차 사용 규정을 알려주세요.',
                '재택근무 시 적용되는 규정은 무엇인가요?',
                '회사 내 휴가 정책에 대해 설명해 주세요.',
            ]);
        }
    }, [chatType]);

    return { inputText, setInputText, messageOptions, emailTemplate };
}
