import { MessageListProps } from 'Message';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const formatText = (text: string | undefined) => {
  return (text ?? '').split('\n').map((str, index) => (
    <React.Fragment key={index}>
      {str}
      <br />
    </React.Fragment>
  ));
};

function Message({ messages }: MessageListProps) {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);
  return (
    <ChatContainer ref={chatContainerRef}>
      {messages.map((message) =>
        message.isUser && !(message.isUser === 'system') ? (
          <>
            <UserMessageBubble key={message.id} isUser={true}>
              {formatText(message.text)}
              <br />
            </UserMessageBubble>
          </>
        ) : (
          <>
            <BotMessageBubble key={message.id} isUser={false}>
              {formatText(message.text)}
              <br />
              <br />
            </BotMessageBubble>
          </>
        )
      )}
    </ChatContainer>
  );
}

export default React.memo(Message);

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 640px;
  height: auto;
  max-height: 700px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    width: 340px;
  }
`;

interface MessageBubbleProps {
  isUser: boolean;
}

const UserMessageBubble = styled.h4<MessageBubbleProps>`
  max-width: 58%;
  padding: 10px 20px;
  border-radius: 20px;
  margin-bottom: 10px;
  font-weight: 350;
  word-break: keep-all;
  background-color: ${(props) => (props.isUser ? '#2a2a2a' : '#3a3a3a')};
  color: white;
  align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
  user-select: text;

  @media (max-width: 768px) {
    padding: 15px 25px;
    max-width: 90%;
    font-size: 16px;
  }
`;

const BotMessageBubble = styled.div<MessageBubbleProps>`
  max-width: 58%;
  padding: 10px 20px;
  border-radius: 20px;
  margin-bottom: 10px;
  word-break: keep-all;
  background-color: ${(props) => (props.isUser ? '#2a2a2a' : '#3a3a3a')};
  color: white;
  align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
  user-select: text;

  @media (max-width: 768px) {
    padding: 15px 25px;
    max-width: 90%;
    font-size: 16px;
  }
`;
