import React, { useRef, useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import styled, { keyframes } from 'styled-components';
import Load from './Load';
import Message from './Message';
import MessageOptions from './MessageOptions';
import { useChatOptions } from '../hooks/useChatOptions';
import { useSendMessage } from '../hooks/useSendMessage';
import { adjustTextareaHeight } from '../utils/chatInterface.utils';
import { chatInterface } from 'chatInterface';
import { Label } from '../pages/Chat';

function ChatInterface({
  chat,
  chatType,
  isChatListOpen,
  setIsLoading,
}: chatInterface) {
  const [isWork, setIsWork] = useState(false);
  const [isFirstMessage, setIsFirstMessage] = useState(true);
  const { inputText, setInputText, messageOptions, emailTemplate } =
    useChatOptions(chatType);
  const { messages, addMessage, sendMessageToApi, load } = useSendMessage(
    chatType,
    setIsLoading
  );
  const [optionsVisible, setOptionsVisible] = useState<boolean>(
    localStorage.getItem('key') === 'mail' ? false : true
  );
  useEffect(() => {
    const key = localStorage.getItem('key');
    if (key === 'mail') {
      setOptionsVisible(false);
    } else {
      setOptionsVisible(true);
    }
  }, [chatType]);

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleUserInput = useCallback(
    async (text: string) => {
      setIsWork(true);
      if (load) {
        alert('로딩중입니다.');
        return;
      }
      if (text.trim() === '') return;
      addMessage(text, true);
      await sendMessageToApi(text);

      if (chatType === 'mail' && isFirstMessage) {
        setIsFirstMessage(false);
      }

      setInputText('');
      adjustTextareaHeight(inputRef);
    },
    [
      load,
      addMessage,
      sendMessageToApi,
      setInputText,
      chatType,
      emailTemplate,
      isFirstMessage,
    ]
  );

  useEffect(() => {
    if (inputRef.current) {
      if (chatType === 'mail' && isFirstMessage) {
        inputRef.current.style.height = '250px';
        setInputText(emailTemplate);
      } else {
        adjustTextareaHeight(inputRef);
      }
    }
  }, [chatType, isFirstMessage]);

  useEffect(() => {
    adjustTextareaHeight(inputRef);
  }, [inputText]);

  return (
    <ChatContainer hidden={isChatListOpen}>
      <Message messages={messages} />
      {optionsVisible ? <NullBox></NullBox> : null}
      <MessageSort>
        <InputContainer>
          <StyledInput
            ref={inputRef}
            value={inputText}
            onChange={(e) => {
              setOptionsVisible(false);
              setInputText(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleUserInput(inputText);
                setInputText('');
              }
            }}
            onInput={() => adjustTextareaHeight(inputRef)}
            placeholder={'메시지를 입력하세요'}
          />
          {chatType !== 'mail' && (
            <ToggleOptionsButton
              chatType={chatType}
              onClick={() => {
                setOptionsVisible(!optionsVisible);
              }}
            >
              <Label>메세지 전송 예시</Label>
              <FontAwesomeIcon
                icon={faChevronUp}
                rotation={optionsVisible ? 90 : 180}
              />
            </ToggleOptionsButton>
          )}
          <MessageOptions
            options={messageOptions}
            onClick={handleUserInput}
            isLoading={load}
            optionsVisible={optionsVisible}
          />
          <SendButton
            style={{ cursor: load ? 'not-allowed' : 'pointer' }}
            onClick={() => handleUserInput(inputText)}
          >
            {load ? (
              <Load />
            ) : (
              <>
                <Label>메시지 전송</Label>
                <AnimatedIcon
                  aria-label="전송"
                  icon={faPaperPlane}
                  onClick={() => setInputText('')}
                />
              </>
            )}
          </SendButton>
        </InputContainer>
      </MessageSort>
    </ChatContainer>
  );
}

export default React.memo(ChatInterface);

const NullBox = styled.div`
  height: 120px;
  @media (max-width: 768px) {
    min-height: 300px;
  }
`;

const ChatContainer = styled.div<{ hidden: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  background-color: #121212;
  padding: 20px;
  overflow: hidden;
  flex-grow: 2;

  ${({ hidden }) =>
    hidden &&
    `
        @media (max-width: 768px) {
            display: none;
        }
    `}

  @media (max-width: 768px) {
    height: 100%;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  border-radius: 30px;
  padding: 10px 20px;
  width: 100%;
  max-width: 600px;
  margin-bottom: 40px;
  position: relative;
  margin-bottom: 100px;
  border: 1px solid white;
  animation: borderAnimation 3s infinite;

  @media (max-width: 768px) {
    margin-bottom: 170px;
  }

  @keyframes borderAnimation {
    0% {
      border-color: #4a4a4a;
    }
    50% {
      border-color: #66ff00;
    }
    100% {
      border-color: #4a4a4a;
    }
  }
`;

const StyledInput = styled.textarea`
  flex: 1;
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  padding: 10px;
  resize: none;
  overflow-y: auto;
  height: 30px;
  max-height: 250px;
  width: 500px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    width: 200px;
  }
`;

const SendButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ToggleOptionsButton = styled.button<{ chatType: string }>`
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
  margin-right: 10px;
  padding: 10px;
  &:focus {
    outline: none;
  }
`;

const MessageSort = styled.div`
  flex-direction: column-reverse;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// 애니메이션 정의
const colorChange = keyframes`
  0% {
    color: #4a4a4a;
  }
  50% {
    color: #66ff00;
  }
  100% {
    color: #4a4a4a;
  } 
`;

const AnimatedIcon = styled(FontAwesomeIcon)`
  animation: ${colorChange} 3s infinite;
`;
