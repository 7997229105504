import React from 'react';
import styled, { keyframes } from 'styled-components';

const Load: React.FC = () => {
    return <Loader aria-label="로딩중" />;
};

export default Load;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
    position: absolute;
    width: 20px;
    height: 20px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 5px solid #fff;
    animation: ${spin} 1s linear infinite;
`;
