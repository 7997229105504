// ErrorBoundary.tsx
import React, { Component, ReactNode } from 'react';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error('Error caught in Error Boundary: ', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            alert(
                '예기치 못한 버그가 발생했습니다. 개발자에게 신고하시면 적절한 조치를 취하겠습니다.'
            );
            return null; // 필요한 경우 폴백 UI를 렌더링합니다.
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
