// App.tsx
import React from 'react';
import Main from './pages/Main';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Chat from './pages/Chat';
import ErrorBoundary from './components/ErrorBoundary'; // ErrorBoundary 임포트

function App() {
    return (
        <ErrorBoundary>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main></Main>}></Route>
                    <Route path="/chat" element={<Chat></Chat>}></Route>
                </Routes>
            </BrowserRouter>
        </ErrorBoundary>
    );
}

export default App;
