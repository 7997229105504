import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { navState } from 'navigateState';
import SideListItem from './SideListItem';
import { sidebarProps } from 'sideBar';

const chatbots = [
    {
        name: '하티 통합 근무 규정봇',
        key: 'business',
        ariaLabel: '하티 통합 근무 규정봇 사용',
    },
    {
        name: '하티 한국어 근무 규정봇',
        key: 'korea-lan',
        ariaLabel: '하티 한국어 근무 규정봇 사용',
    },
    {
        name: '하티 외국어 근무 규정봇',
        key: 'foreign',
        ariaLabel: '하티 외국어 근무 규정봇 사용',
    },
    {
        name: '한국어 교육 어시스턴트',
        key: 'korea',
        ariaLabel: '한국어 교육 어시스턴트 사용',
    },
    {
        name: '이메일 작성봇',
        key: 'mail',
        ariaLabel: '이메일 작성봇 사용',
    },
];

export default function Sidebar({ chatType, isLoading }: sidebarProps) {
    const [selectedItem, setSelectedItem] = useState<string | null>(
        chatType || ''
    );
    const navigate = useNavigate();
    let state: navState = { key: '' };

    useEffect(() => {
        setSelectedItem(chatType || '');
    }, [chatType]);

    const handleClick = (key: string) => {
        if (!isLoading) {
            // 로딩 중일 때는 클릭 방지
            setSelectedItem(key);
            navigate('/chat', {
                state: { key },
            });
        }
    };

    return (
        <Side isLoading={isLoading}>
            {chatbots.map((bot, index) => (
                <SideListItem
                    key={index}
                    ariaLabel={bot.ariaLabel}
                    name={bot.name}
                    isSelected={selectedItem === bot.key}
                    clickFn={() => handleClick(bot.key)}
                />
            ))}
        </Side>
    );
}

const Side = styled.div<{ isLoading: boolean }>`
    width: 230px;
    background-color: #23262a;
    padding: 20px;
    display: flex;
    flex-direction: column;
    opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
    pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};

    @media (max-width: 768px) {
        display: none;
    }
    border-right: 1px solid #585e70;
`;
