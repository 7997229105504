// ChatInterface.utils.ts
import { RefObject } from 'react';

export const adjustTextareaHeight = (
    inputRef: RefObject<HTMLTextAreaElement>
) => {
    if (inputRef.current) {
        inputRef.current.style.height = '40px';
        inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
};
