import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { MessageOptionsProps } from 'MessageOptions';

export default function MessageOptions({
    options,
    onClick,
    isLoading,
    optionsVisible,
}: MessageOptionsProps & { optionsVisible: boolean }) {
    const [shouldRender, setShouldRender] = useState(optionsVisible);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (optionsVisible) {
            setShouldRender(true);
        } else {
            timeoutId = setTimeout(() => setShouldRender(false), 300);
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [optionsVisible]);

    if (!shouldRender) return null;

    return (
        <OptionsContainer visible={optionsVisible}>
            {options.map((option, index) => (
                <OptionButton
                    isLoading={isLoading}
                    key={index}
                    onClick={isLoading ? undefined : () => onClick(option)}
                >
                    {option}
                </OptionButton>
            ))}
        </OptionsContainer>
    );
}

// 애니메이션 정의
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const OptionsContainer = styled.div<{ visible: boolean }>`
    position: absolute;
    bottom: 100%;
    left: -40%;
    width: 180%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 30px 50px;
    animation: ${({ visible }) => (visible ? fadeIn : fadeOut)} 0.3s ease;

    @media (max-width: 768px) {
        left: -20%;
        width: 140%;
        padding: 20px 30px;
    }

    @media (max-width: 480px) {
        left: -10%;
        width: 120%;
        padding: 15px 20px;
    }
`;

const OptionButton = styled.button<{ isLoading: boolean }>`
    background-color: #2a2a2a;
    opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 200px;

    &:hover {
        background-color: #3a3a3a;
    }
`;
