import styled from 'styled-components';
import imageSrc from '../assets/file.png';
import { useNavigate, useLocation } from 'react-router-dom';
import React from 'react';

export default function TopNav({ isLoading = false }: { isLoading?: boolean }) {
    const navigate = useNavigate();
    const location = useLocation();

    // 페이지가 Main인 경우 햄버거 메뉴 숨기기
    const isMainPage = location.pathname === '/';

    return (
        <Top isLoading={isLoading}>
            <MainLogo
                onClick={() => !isLoading && navigate('/')}
                isLoading={isLoading}
            >
                <CircleImageWrapper>
                    <CircleImage src={imageSrc} alt="하티웍스 로고" />
                </CircleImageWrapper>
                <TopHeader>하티웍스</TopHeader>
            </MainLogo>
        </Top>
    );
}

const Top = styled.div<{ isLoading: boolean }>`
    display: flex;
    background-color: ${({ isLoading }) => (isLoading ? '#2c2f34' : '#1c1e24')};
    height: 70px;
    align-items: center;
    border-bottom: 0.5px solid
        ${({ isLoading }) => (isLoading ? '#454a57' : '#585e70')};
    position: relative;
    pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};

    @media (max-width: 768px) {
        justify-content: center;
    }
`;

const MainLogo = styled.div<{ isLoading: boolean }>`
    display: flex;
    align-items: center;
    height: 50px;
    margin-left: 50px;
    cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
    padding: 0 10px;
    background-color: ${({ isLoading }) =>
        isLoading ? '#2c2f34' : 'transparent'};
    opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};

    &:hover {
        background: ${({ isLoading }) => (isLoading ? '#2c2f34' : '#3a3d41')};
        border-radius: 8px;
    }

    @media (max-width: 768px) {
        margin-left: 0px;
    }
`;

const CircleImageWrapper = styled.div`
    width: 50px;
    height: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CircleImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const TopHeader = styled.h1`
    font-size: 24px;
    color: white;
    display: flex;
    align-items: center;
    margin-left: 10px;
`;
